.payment-frame {
    width: 100%;
    border-radius: 10px;
    max-width: 100%; 
    border: none; 
    overflow: hidden;
  }
  
  .payment-frame.card {
    height: 450px;
  }
  
  .payment-frame.other {
    height: 350px;
  }
  
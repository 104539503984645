progress {
    align-self: center;
    justify-self: flex-start;
    display: flex;
    height: 5px;
    border-radius: 25px; /* Add border radius if desired */
  }
  
  progress::-webkit-progress-bar {
    background-color: #97f3ff; /* Set background color for the progress bar container */
    border-radius: 25px; /* Add border radius if desired */
    
  }
  
  progress::-webkit-progress-value {
    background-color: #00BDD6; /* Set background color for the progress bar itself */
    border-radius: 25px; /* Add border radius if desired */
    
  }
  .StatsBox {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .StatsRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80%;
    margin-bottom: 0.5rem;
  }
  .flexDefaults{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .Stats_perentageBox{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #F5F2FD;
    border-radius: 20px;
    width: 40px;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }